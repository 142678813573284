<template>
  <view-card-template :loaded="loaded">

    <template #cardTitle>
      Preferences
    </template>

    <template #cardBody>
      <v-container
        class="px-0 py-4 d-flex flex-column justify-space-between"
        style="height: 100%;"
      >

        <!-- START Voter Preference Sections -->
        <div
          v-if="voterPreferences != null"
        >
          <!-- Section Header -->
          <v-row
            class="px-6 py-3 flex-grow-0"
            no-gutters
          >
            <v-col cols="9">
              <h3>Notifications</h3>
            </v-col>
            <v-spacer/>
            <v-col cols="3">
              <div class="d-flex justify-center">
                <v-icon class="px-1">mdi-email</v-icon>
                <div class="font-weight-medium">EMAIL</div>
              </div>
            </v-col>
          </v-row>

          <!-- Preferences -->
          <v-row
            class="px-6 py-2 flex-grow-0"
            style="background-color: #eee"
            no-gutters
          >
            <v-col cols="9">
              <h4>Nominations</h4>
              <p class="text-subtitle-2 font-weight-light">Get notified when awards first open for nominations</p>
            </v-col>
            <v-spacer/>
            <v-col cols="3">
              <div
                style="height: 100%;"
                class="d-flex justify-center align-center"
              >
                <v-checkbox
                  v-model="voterPreferences.recieveNominationPhaseNotifications"
                  class="pa-0 ma-0"
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-row
            class="px-6 py-2 flex-grow-0"
            no-gutters
          >
            <v-col cols="9">
              <h4>Voting</h4>
              <p class="text-subtitle-2 font-weight-light">Get notified when awards first open for voting</p>
            </v-col>
            <v-spacer/>
            <v-col cols="3">
              <div
                style="height: 100%;"
                class="d-flex justify-center align-center"
              >
                <v-checkbox
                  v-model="voterPreferences.recieveVotingPhaseNotifications"
                  class="pa-0 ma-0"
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-row
            class="px-6 py-2 flex-grow-0"
            no-gutters
          >
            <v-col cols="9">
              <h4>Nomination Submission</h4>
              <p class="text-subtitle-2 font-weight-light">Get a confirmation email when submitting nominations</p>
            </v-col>
            <v-spacer/>
            <v-col cols="3">
              <div
                style="height: 100%;"
                class="d-flex justify-center align-center"
              >
                <v-checkbox
                  v-model="voterPreferences.receiveNominationSubmissionNotifications"
                  class="pa-0 ma-0"
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-row
            class="px-6 py-2 flex-grow-0"
            no-gutters
          >
            <v-col cols="9">
              <h4>Voting Submission</h4>
              <p class="text-subtitle-2 font-weight-light">Get a confirmation email when submitting votes</p>
            </v-col>
            <v-spacer/>
            <v-col cols="3">
              <div
                style="height: 100%;"
                class="d-flex justify-center align-center"
              >
                <v-checkbox
                  v-model="voterPreferences.receiveVotingSubmissionNotifications"
                  class="pa-0 ma-0"
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- END Voter Preference Sections -->

        <!-- START Page Actions -->
        <v-card-actions style="margin-top: auto;">
          <v-spacer/>
          <v-btn
            @click="navigateBack"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="saveVoterPreferences()"
            :loading="savePreferencesLoading"
            color="primary"
          >
            Save
          </v-btn>
        </v-card-actions>
        <!-- END Page Actions -->

      </v-container>
    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from '@/data/controllers'

export default {
  name: "VoterPreferences",

  components: {
    ViewCardTemplate,
  },

  props:  {
    //
  },

  data: () => ({
    loaded: false,
    savePreferencesLoading: false,
    
    voterPreferences: null,
  }),

  computed: {
    //
  },

  methods: {
    navigateBack() {
      this.$router.push({ name: "Voting" })
    },

    async saveVoterPreferences() {
      this.savePreferencesLoading = true

      const res = await Controllers.UserController.UpdateUserPreferences(this.voterPreferences)
      
      if(res && !res.hasError) {
        this.$root.showSnackbar('Preferences saved successfully!', 'success', 5000)
        // this.navigateBack()
      }
      else {
        // Error handling
        this.$root.showSnackbar('There was an error saving your preferences.', 'error', 5000)
      }
      
      this.savePreferencesLoading = false
    }
  },

  async created() {
    this.$root.updateTitle("Preferences")

    const res = await Controllers.UserController.GetUserPreferences()
    
    if(res && !res.hasError) {
      this.voterPreferences = res.data
    }
    else {
      // Error handling
    }

    this.loaded = true
  }
}
</script>

<style>

</style>