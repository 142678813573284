<template>
  <view-card-template :loaded="false"></view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

export default {
  name: "ClearState",
  components: {
    ViewCardTemplate,
  },
  created() {
    // Refresh global state.
    this.$store.dispatch("clearConferenceDivisions")
    this.$store.dispatch("clearConferenceSeasons")
    this.$store.dispatch("clearConferenceSports")

    // Load a fresh /voting
    this.$router.push({ path: '/voting' })
  }
}
</script>